export default {
    contactBtn: 'お問い合わせ',
    singIn: 'ログイン',
    header: [
        {
            name: 'プロダクト',
            url: '/product',
            list:[
                {
                    name: '音声認識',
                    items: [
                        {
                            imgUrl: 'real_time.png',
                            name: 'リアルタイム音声認識',
                            content: '優れたリアルタイム性と正確性。',
                            url: '/product/real-time-transcription'
                        },
                        // {
                        //     imgUrl: 'upload_g.png',
                        //     name: '録音ファイル書き起こし（通常版）',
                        //     content: '音声・ビデオファイルをテキストに書き換え、テキストの議事録形式と字幕形式を提供します。',
                        //     url: '/product/tse'
                        // },
                        {
                            imgUrl: 'upload_e.png',
                            name: '録音ファイル書き起こし',
                            content: '30分間の音声・ビデオファイルは30秒以内に書き換え完了できます。',
                            url: '/product/tese'
                        },
                        {
                            imgUrl: 'short.png',
                            name: '一発話認識',
                            content: '60秒以下の音声に対して書き換えを行い、音声インタラクションアプリケーションに適しています。',
                            url: '/product/short-speech-transcription'
                        },
                        {
                            imgUrl: 'languages.png',
                            name: '多言語対応',
                            content: '世界中の数十種類の主流言語をサポートし、認識の正確率は95％を超えます。',
                            url: '/product/languages'
                        }
                    ]
                },
                {
                    name: '音声拡張機能',
                    items: [
                        {
                            imgUrl: 'language_identify.png',
                            name: '言語識別 (Beta)',
                            content: '高効率な言語識別ツールで、迅速な言語の識別を支援します。',
                            url: '1-6',
                            disabled: true
                        },
                        {
                            imgUrl: 'keywords_spotting.png',
                            name: 'ウェークアップ (Beta)',
                            content: '敏感で便利な機能で、いつでも応答の準備ができており、ユーザー体験を向上させます。',
                            url: '1-7',
                            disabled: true
                        },
                        {
                            imgUrl: 'speaker_recognition.png',
                            name: '声紋認証 (Beta)',
                            content: '唯一の声紋特徴検証で、システムの安全性を守護します。',
                            url: '1-8',
                            disabled: true
                        }
                    ]
                },
                // {
                //     name: 'Translation',
                //     items: [
                //         {
                //             imgUrl: 'translation.png',
                //             name: 'Translation',
                //             content: 'Support multi-language translation, applicable to translators, simultaneous interpretation.',
                //             url: '/product/translation'
                //         }
                //     ]
                // },
                {
                    name: 'デプロイ',
                    items: [ 
                        {
                            imgUrl: 'prem.png',
                            name: 'On-Prem',
                            content: 'ローカルデプロイをサポートし、より高いセキュリティとプライバシーを実現します。',
                            url: '/product/on-prem'
                        },
                        {
                            imgUrl: 'device.png',
                            name: 'On-Device',
                            content: '離線デバイス上で音声をテキストに変換し、メモリ使用率が低く、モデルが簡素化され、多言語をサポートしています。',
                            url: '/product/on-device'
                        },
                        {
                            imgUrl: 'API.png',
                            name: 'Cloud API',
                            content: '私たちのAPIの利点とその動作原理を理解してください。',
                            url: '/product/cloud-api'
                        }
                    ]
                }
            ]
        },
        {
            name: '特徴',
            url: '/features',
            list: [
                {
                    name: '',
                    mName: 'Features',
                    items: [
                        {
                            imgUrl: 'multi_languages_recognition.png',
                            name: '多言语対応',
                            content: 'ネームレスなコミュニケーションで、多様な文化を抱きしめて！',
                            url: '/features?url=multi-language-support'
                        },
                        {
                            imgUrl: 'code_switching.png',
                            name: 'バイリンガル認識',
                            content: '多言語環境を簡単に処理し、流れがスムーズで自然です！',
                            url: '/features?url=code-switching'
                        },
                        {
                            imgUrl: 'rescource_saving.png',
                            name: 'リソース節約',
                            content: '高効率なアルゴリズムで、CPUでデプロイが可能で、高価なGPUコストは必要ありません。',
                            url: '/features?url=resource-saving'
                        },
                        {
                            imgUrl: 'captioning.png',
                            name: 'リアルタイム字幕',
                            content: '音声からリアルタイムに字幕を生成し、多様なニーズに応えます！',
                            url: '/features?url=real-time-subtitles'
                        },
                        {
                            imgUrl: 'characteristic_features.png',
                            name: '高度な特徴',
                            content: '音声認識システムは、異なるアプリケーションシナリオに適応するための強化された機能やサービスを提供します。',
                            url: '/features?url=advanced-features'
                        },
                        {
                            imgUrl: 'full_link.png',
                            name: 'フルリンク',
                            content: '音声信号処理、音声認識から音声合成まで、ワンストップの専門音声インタラクション技術解決策です！',
                            url: '/features?url=full-link'
                        }
                    ]
                }
            ]
        },
        {
            name: 'リソース',
            url: '/documentation',
            list:[
                {
                    name: 'ドキュメント',
                    items: [
                        {
                            imgUrl: 'API.png',
                            name: 'Cloud API',
                            content: '私たちのAPIの利点とその動作原理を理解してください。',
                            url: '/documentation?url=api/intro/notice.md'
                        },
                        {
                            imgUrl: 'device.png',
                            name: 'On-Device',
                            content: '離線デバイス上で音声をテキストに変換し、メモリ使用率が低く、モデルが簡素化され、多言語をサポートしています。',
                            url: '/documentation?url=on-device/asr/android.md'
                        },
                        {
                            imgUrl: 'prem_resource.png',
                            name: 'On-Prem (Eng. Edition)',
                            content: 'プロダクト一覧、デプロイメントマニュアル、および API プロトコルを確認して、オンプレミスプロダクトに迅速に接続するのに役立ちます。',
                            url: '/documentation?url=on-prem/intro/overview.md'
                        }
                    ]
                },
                {
                    name: '会社情報',
                    items: [
                        {
                            imgUrl: 'about.png',
                            name: '会社概要',
                            content: '私たちは、最高品質の技術とサービスを提供することを心掛けています。',
                            url: '/about'
                        },
                        {
                            imgUrl: 'security.png',
                            name: 'セキュリティ体系',
                            content: "お客様が安心してDolphinVoiceをご利用いただけるよう。",
                            url: '/security'
                        }
                    ]
                },
                // {
                //     name: 'ダウンロード',
                //     items: [
                //         {
                //             imgUrl: 'sdk.png',
                //             name: 'name',
                //             content: 'Blabla',
                //             url: '4'
                //         }
                //     ]
                // }
            ]
        },
        {
            name: 'メディア',
            url: '/blog'
        }
    ],
    footer: {
        banner: {
            tip1: '始める準備はできましたか？',
            tip2: "AI音声技術サービスの世界最優の選択。",
            btnText: 'お問い合わせ'
        },
        menus: {
            row1: [
                {
                    name: 'E-mail',
                    email: 'voice.contact@dolphin-ai.jp'
                },
                {
                    name: 'Address',
                    addr: '〒170-0013<br/>東京都豊島区東池袋1-18-1<br/>ハレザタワー 20階'
                }
            ],
            row2: {
                name: 'プロダクト',
                list1: [
                    {
                        name: 'リアルタイム音声認識',
                        url: '/product/real-time-transcription'
                    },
                    // {
                    //     name: '録音ファイル書き起こし（通常版）',
                    //     url: '/product/tse'
                    // },
                    {
                        name: '録音ファイル書き起こし',
                        url: '/product/tese'
                    },
                    {
                        name: '一発話認識',
                        url: '/product/short-speech-transcription'
                    }
                ],
                list2: [
                    {
                        name: '多言語対応',
                        url: '/product/languages'
                    },
                    // {
                    //     name: 'Translation',
                    //     url: '/product/translation'
                    // },
                    {
                        name: '言語識別 (Beta)',
                        url: 'disabled'
                    },
                    {
                        name: 'ウェークアップ (Beta)',
                        url: 'disabled'
                    },
                    {
                        name: '声紋認証 (Beta)',
                        url: 'disabled'
                    }
                ],
                list3: [
                    {
                        name: 'On-Prem',
                        url: '/product/on-prem'
                    },
                    {
                        name: 'On-Device',
                        url: '/product/on-device'
                    },
                    {
                        name: 'Cloud API',
                        url: '/product/cloud-api'
                    }
                ]
            },
            row3: {
                name: '特徴',
                list: [
                    {
                        name: '多言语対応',
                        url: '/features?url=multi-language-support'
                    },
                    {
                        name: 'バイリンガル認識',
                        url: '/features?url=code-switching'
                    },
                    {
                        name: 'リソース節約',
                        url: '/features?url=resource-saving'
                    },
                    {
                        name: 'リアルタイム字幕',
                        url: '/features?url=real-time-subtitles'
                    },
                    {
                        name: '高度な特徴',
                        url: '/features?url=advanced-features'
                    },
                    {
                        name: 'フルリンク',
                        url: '/features?url=full-link'
                    }
                ]
            },
            row4: {
                name: 'リソース',
                list1: [
                    {
                        name: 'Cloud API',
                        url: 'api/intro/notice.md'
                    },
                    {
                        name: 'On-Device',
                        url: 'on-device/asr/android.md'
                    },
                    {
                        name: 'On-Prem (Eng. Edition)',
                        url: 'on-prem/intro/overview.md'
                    }
                ],
                list2: [
                    // {
                    //     name: 'SDK Download',
                    //     url: ''
                    // }
                ]
            },
            row5: {
                name: 'Company',
                list1: [
                    {
                        name: '会社情報',
                        url: '/about'
                    },
                    {
                        name: 'お問い合わせ',
                        url: '/contact-us'
                    },
                    {
                        name: 'ヘルプセンター',
                        url: 'api/intro/notice.md'
                    }
                ],
                list2: [
                    {
                        name: 'プライバシー',
                        url: 'common/privacy'
                    },
                    {
                        name: '利用規約',
                        url: 'common/tos'
                    }
                ]
            }
        },
        cpr: 'Copyright © 2024 Dolphin.AI Corporation'
    },
    more: '詳細へ',
    soon: 'お楽しみに',
    free_btn: 'お問い合わせ',
    home: {
        exp: {
            text1: '私たちの音声認識APIを選んで、ビジネスをより迅速かつ低コストで拡大しましょう。',
            btn1: '詳細へ',
            text2: '先進の',
            text_blue: '音声知能',
            text3: '技術は、ユーザー体験向上の基礎',
            text4: '業界をリードする音声大モデル技術と膨大なデータを基に、豊富な音声認識機能を提供し、APIを介して迅速にアクセスできます。',
            btn2: 'お問い合わせ',
            tabs_name1: 'リアルタイム音声認識',
            tabs_name2: '録音ファイル書き起こし',
            asrTip: 'マイクをクリックして試し始めましょう',
            asrStart: '音声認識スタート',
            asrStop: '音声認識停止',
            tip: 'デモ用にのみ使用してください。フル機能を体験するには登録してください。',
            time: 'タイマー',
            reset: 'やり直し',
            fileTip: 'ファイルをドラッグ&ドロップ、またはクリックしてアップロードしてください',
            format: '対応形式：WAV, PCM, OPUS, MP3, AMR, AAC',
            fileSize: 'ファイルサイズ制限：50M',
            fileBtn: '書き起こしスタート',
            downloadType: [
                {
                    label: 'TXT文章形式としてダウンロード',
                    value: 'text'
                },
                {
                    label: 'SRT字幕形式としてダウンロード',
                    value: 'srt'
                }
            ],
            fileList: {
                title: 'ファイル：',
                status1: '追加されました',
                status2: 'アップロード中',
                status3: '処理中'
            }
        },
        speech: {
            h2: '音声認識APIと豊富なSDK',
            h3: '業界を先導するAI大モデルに基づき、私たちの音声認識技術は高品質なサービスを保証します。',
            info: '音声認識の正確性に加えて、私たちは多種の実用的な機能を提供しています。オンラインで体験し、比較テストを行い、最も理想的な解決策を見つけることができます。',
            btn: 'お問い合わせ',
            contrastImg: 'contrast_ja.png'
        },
        offers: {
            h2: 'Dolphin Voiceが最適な選択を提供する',
            h3: '世界をリードするAI音声認識技術が、あなたのビジネスを支援します。高効率で正確なコアエンジンによって音声インタラクションを保護し、現在では20以上の言語をサポートしています。',
            list: [
                {
                    title: 'より高い正確性',
                    name: 'higher_accuracy',
                    mIcon: 'higher_accuracy',
                    info: 'Dolphin Voiceの高正確性の音声認識技術は、発言ごとに正確に認識できます。強力なアルゴリズムに基づき、私たちのエンジンは誤り率を最大限に低減し、信頼できる音声認識結果を提供し、重要なビジネス意思決定の支援を行います。'
                },
                {
                    title: 'より低い料金',
                    name: 'lower_price',
                    mIcon: 'lower_price',
                    info: 'Dolphin Voiceを使用することで、高品質を保証しながら運営コストを削減できます。私たちは経済的で効率的な解決策を提供し、極めて低い価格で先進の音声認識技術を提供することを目指しています。音声技術の利点を享受しながら、投資のリターンを最大限に実現します。'
                },
                {
                    title: '専門的で、効率的で、安定している',
                    name: 'professional_stable',
                    mIcon: 'professional_stable',
                    info: 'Dolphin VoiceはISO 27001情報セキュリティ体系认证を受けており、7x24時間の継続運用に特化して設計されています。私たちのシステムは高負荷の使用シナリオに対応でき、あなたのビジネスニーズを満たします。'
                },
                {
                    title: '国際化対応',
                    name: 'international_support',
                    mIcon: 'international_support',
                    info: "Dolphin Voiceの多言語機能を利用して、あなたの世界中の影響力を拡大してください。私たちのエンジンは20以上の言語をサポートしており、あなたの企業が海外ビジネスを展開し、世界市場でシームレスに運営するのに役立ちます。"
                }
            ]
        },
        support: {
            h2: '多言語による国際化対応',
            h3: '多言語の音声認識と翻訳、リアルタイム書き起こし、柔軟なデプロイメントで、国際化された顧客のニーズに応えます。',
            languages: [
                {
                    name: '日本語',
                    c_flag: 'Japan'
                },
                {
                    name: '英語',
                    c_flag: 'USA'
                },
                {
                    name: '中国語<br/>（標準語）',
                    c_flag: 'China'
                },
                {
                    name: '韓国語',
                    c_flag: 'Korea'
                },
                {
                    name: 'ロシア語',
                    c_flag: 'Russia'
                },
                {
                    name: 'タイ語',
                    c_flag: 'Thailand'
                },
                {
                    name: 'ベトナム語',
                    c_flag: 'Vietnam'
                },
                {
                    name: 'ヒンディー語',
                    c_flag: 'India'
                },
                {
                    name: 'アラビア語',
                    c_flag: 'Arab'
                },
                {
                    name: 'ビルマ語',
                    c_flag: 'Myanmar'
                },
                {
                    name: 'インドネシア語',
                    c_flag: 'Indonesia'
                },
                {
                    name: 'マレー語',
                    c_flag: 'Malaysia'
                },
                {
                    name: 'スペイン語',
                    c_flag: 'Spain'
                },
                {
                    name: 'ドイツ語',
                    c_flag: 'Germany'
                },
                {
                    name: 'フランス語',
                    c_flag: 'France'
                },
                {
                    name: 'イタリア語',
                    c_flag: 'Italy'
                },
                {
                    name: 'ポーランド語',
                    c_flag: 'Poland'
                },
                {
                    name: 'オランダ語',
                    c_flag: 'Netherlands'
                },
                {
                    name: 'ポルトガル語',
                    c_flag: 'Portugal'
                },
                {
                    name: 'カザフ語',
                    c_flag: 'Kazakhstan'
                },
                {
                    name: 'モンゴル語',
                    c_flag: 'Mongolia'
                }
            ],
            btn: 'すべての言語...'
        },
        scenarios: {
            h2: '多様なシナリオに適した音声認識',
            h3: '私たちは、プライベートクラウドデプロイメント、クラウドAPI、オフラインSDKなど、多様な形態の音声認識製品を提供しており、柔軟な統合により異なるシナリオのニーズに対応できます。',
            list: [
                {
                    h5: '一発話認識',
                    h6: '60秒以下の音声に対して書き起こしを行い、迅速に認識結果を得ることができます。',
                    url: '/product/short-speech-transcription'
                },
                {
                    h5: 'リアルタイム音声認識',
                    h6: '優れたリアルタイム性と正確性。',
                    url: '/product/real-time-transcription'
                },
                {
                    h5: '録音ファイル書き起こし',
                    h6: '音声・ビデオファイルのアップロードと迅速な書き起こしをサポートしています。',
                    url: '/product/tese'
                },
                {
                    h5: 'オフラインSDK言語認識',
                    h6: '離線デバイス上で音声をテキストに変換し、メモリ使用率が低く、モデルが簡素化され、多言語をサポートしています。',
                    url: '/product/on-device'
                }
            ]
        },
        products: {
            h2: '多様なプロダクトを簡単な導入',
            h3: 'Dolphin Voiceは柔軟なデプロイメントをサポートしており、異なる顧客のデータセキュリティとプライバシーニーズをよりよく満たします。',
            list: [
                {
                    h5: 'Cloud API',
                    h6: '登録後、すぐに音声認識APIにアクセスでき、豊富なサンプルコードとドキュメントを提供しています。',
                    url: 'api/intro/notice.md',
                    mImg: 'API.svg'
                },
                {
                    h5: 'On-Device',
                    h6: '音声認識エンジンをiOS、Androidなどのさまざまなデバイスにデプロイでき、インターネット接続なしで音声認識を実行できます。',
                    url: 'on-device/asr/android.md',
                    mImg: 'SDK.svg'
                },
                {
                    h5: 'On-Prem',
                    h6: 'お客様の独自のサーバーに音声認識エンジンをデプロイし、クラウドサーバーでのデプロイやローカルデプロイをサポートしています。',
                    url: '/product/on-prem',
                    mImg: 'Prem.svg'
                }
            ]
        },
        individual: {
            h2: '個々のニーズに応える実用的な機能',
            h3: 'お客様のニーズこそが私たちがすべきことであり、技術の価値はお客様にとって価値を創造することにあります。',
            list1: [
                {
                    bg: 'asr',
                    info1: '多言语対応',
                    info2: 'ネームレスなコミュニケーションで、多様な文化を抱きしめて！'
                },
                {
                    bg: 'code',
                    info1: 'バイリンガル認識',
                    info2: 'ネームレスなバイリンガル切り替えで、音声認識が流れがスムーズで自然です。'
                },
                {
                    bg: 'resource',
                    info1: 'リソース節約',
                    info2: '高効率なアルゴリズムで、CPUでデプロイが可能で、高価なGPUコストは必要ありません。'
                },
                {
                    bg: 'captioning',
                    info1: 'リアルタイム字幕',
                    info2: '音声からリアルタイムに字幕を生成し、多様なニーズに応えます！'
                },
                {
                    bg: 'characteristic',
                    info1: '特徴',
                    info2: '音声認識システムは、異なるアプリケーションシナリオに適応するための強化された機能やサービスを提供します。'
                },
                {
                    bg: 'full_link',
                    info1: 'フルリンク',
                    info2: '音響モデルから言語モデルまでフルカバー、ワンストップの専門音声インタラクション技術解決策です！'
                }
            ],
            ask: {
                title: 'FAQ'
            },
            list2:[
                {
                    q: '音声認識はどの言語をサポートしていますか？',
                    a: '現在、日本語、英語、中国語、日本語と英語の混在、中国語と英語の混在をサポートしています。'
                },
                {
                    q: '音声認識はどのオーディオ形式をサポートしていますか？',
                    a: 'PCM、WAV、MP3、Opusなどをサポートしています。'
                },
                {
                    q: "どのプログラミング言語をサポートできますか？",
                    a: 'WebSocket、Python、C、Android、iOS、H5/JSをサポートしています。'
                },
                {
                    q: 'どのサンプリングレートとビット深度をサポートしていますか？',
                    a: '現在、16000 Hzのサンプリングレートのみをサポートしており、音声のビット深度は16ビットです。'
                },
                {
                    q: '認識結果のレスポンスタイムはどれくらいですか？',
                    a: 'レスポンスタイムは500ミリ秒以下です。'
                },
                {
                    q: 'オフライン音声認識はサポートしていますか？',
                    a: 'はい、現在オフライン音声認識はAndroidとiOSのプラットフォームをサポートしています。'
                }
            ]
        },
        news: {
            h2: 'ニュース',
            btn: 'More'
        }
    },
    pscTitle: '利用シナリオ ',
    sst: {
        p1: {
            title: '一発話認識',
            text: '60秒以下の音声に対して書き換えを行い、音声インタラクションアプリケーションに適しています。',
            imglists: ['TOYKO_univers.png', 'KYOTO_univers.svg', 'MUFG.svg', 'notta.svg']
        },
        h4: '一発話認識技術を使用することで、さまざまなシナリオのニーズに対応できます。',
        pscLists: [
            {
                title: '音声入力',
                imgUrl: 'scenarios_1.jpg',
                content: '車載ナビゲーションやソーシャルチャットなど、多くのシナリオで音声入力を行い、手を解放しコミュニケーションの効率を向上させます。'
            },
            {
                title: '音声コマンド',
                imgUrl: 'scenarios_2.jpg',
                content: 'コマンド辞書に、スマートホーム、ウェアラブルデバイスなどの音声コマンドをマッチさせます。'
            },
            {
                title: 'ヒューマンマシンインタラクション',
                imgUrl: 'scenarios_3.jpg',
                content: 'ロボット、セルフサービス機器などの様々な対話システムに統合され、人と機械の自然なコミュニケーションのニーズを満たします。'
            }
        ],
        advantage: {
            h2: '技術的優位性',
            h4: '私たちの先進技術は製品に強大な優位性を提供し、常に競合他社より一歩先んじてサポートします。',
            prompt: {
                ptitle: '即時の応答',
                pinfo1: '音声認識結果を迅速に返して、低レイテンシの要件を満たします。',
                pinfo2: '先進なアルゴリズムと効率的な処理フレームワークに基づき、即時の応答と正確な音声認識を確保し、これはインスタントメッセンジャーやオンラインゲームなど、リアルタイム性能を要求するアプリケーションにとって不可欠です。私たちのサービスはレイテンシを大幅に低減し、迅速でシームレスなインタラクションを通じて、ユーザー体験を向上させます。',
                contrastImg: 'sst_contrast_ja.png'
            },
            multiLangs: {
                ptitle: '多言語対応',
                pinfo: '私たちの音声認識技術は日本語、英語、中国語標準語など、世界中の20以上の言語をサポートしており、スマートホーム、車載デバイスなど多言語でのインタラクションシーンに適しています。ユーザーはどこにいても、便利な自然な音声インタラクション体験を楽しめます。ユーザーはスマートスピーカーに対してコマンドを出すことで家にあるデバイスを制御したり、運転中に音声でナビゲーションを設定したり情報を得たりすることができます。強力な多言語モデルは、あなたの製品が異なる国と地域のユーザーのニーズに応え、グローバルなサービスを実現するのに役立ちます。'
            },
            languagesList: [
                {
                    name: '日本語',
                    c_flag: 'Japan'
                },
                {
                    name: '英語',
                    c_flag: 'USA'
                },
                {
                    name: '中国語<br/>（標準語）',
                    c_flag: 'China'
                },
                {
                    name: '韓国語',
                    c_flag: 'Korea'
                },
                {
                    name: 'ロシア語',
                    c_flag: 'Russia'
                },
                {
                    name: 'タイ語',
                    c_flag: 'Thailand'
                },
                {
                    name: 'ベトナム語',
                    c_flag: 'Vietnam'
                },
                {
                    name: 'ヒンディー語',
                    c_flag: 'India'
                },
                {
                    name: 'アラビア語',
                    c_flag: 'Arab'
                },
                {
                    name: 'ビルマ語',
                    c_flag: 'Myanmar'
                },
                {
                    name: 'インドネシア語',
                    c_flag: 'Indonesia'
                },
                {
                    name: 'マレー語',
                    c_flag: 'Malaysia'
                },
                {
                    name: 'スペイン語',
                    c_flag: 'Spain'
                },
                {
                    name: 'ドイツ語',
                    c_flag: 'Germany'
                },
                {
                    name: 'フランス語',
                    c_flag: 'France'
                },
                {
                    name: 'イタリア語',
                    c_flag: 'Italy'
                },
                {
                    name: 'ポーランド語',
                    c_flag: 'Poland'
                },
                {
                    name: 'オランダ語',
                    c_flag: 'Netherlands'
                },
                {
                    name: 'ポルトガル語',
                    c_flag: 'Portugal'
                },
                {
                    name: 'カザフ語',
                    c_flag: 'Kazakhstan'
                },
                {
                    name: 'モンゴル語',
                    c_flag: 'Mongolia'
                }
            ],
            btn: 'すべての言語...',
            techLeadership: {
                ptitle: '最先端の技術',
                pinfo1: '大量の音声データとテキストデータを基に、業界をリードするエンドツーエンドの音声認識システムを構築しました。98％の認識正確率を達成しました。',
                pinfo2: '先進のAI技術を利用し、大量のトレーニングデータセットと業界をリードする音声認識フレームワークに基づいて、高精度の音声認識モデルを構築しました。複雑な状況下でも高い認識正確率を維持でき、会議のメモや医療文書記録などのさまざまなビジネスシナリオに適しています。'
            },
            puncPrediction: {
                ptitle: 'より速い音声認識',
                pinfo1: '神経ネットワークと大規模なテキストデータを基に、句読点や文の区切りを知能的に予測します。',
                pinfo2: '神経ネットワークを利用して大量のテキストデータセットを深く分析し、句読点や文の区切りを知能的に予測することで、テキストの可読性と自然さを高め、ユーザーの理解を容易にします。'
            }
        }
    },
    rtt: {
        p1: {
            title: 'リアルタイム音声認識',
            text: 'リアルタイム音声ストリームの音声認識をサポートし、認識結果を即時に返して、長い発言を迅速かつ正確に書き起こし、先進的な音声認識技術であなたのコミュニケーション方法を根本的に変革します。',
            imglists: ['TOYKO_univers.png', 'KYOTO_univers.svg', 'MUFG.svg', 'notta.svg']
        },
        pss: {
            h2: '利用シナリオ',
            h4: '高効率で正確なリアルタイム音声認識で、応用範囲が広く、顧客のニーズを全面的に満たします。',
            scenarios: [
                {
                    title: '議事録',
                    info: '会議内容をリアルタイムで文字に書き起こし、参加者が記録やポイントの復習を容易に行えるようにします。',
                    icon: 'service_1.svg'
                },
                {
                    title: 'インタビュー',
                    info: '迅速で便利な音声聴取方案で、多様なシナリオでの音声から文字への書き起こしニーズに応えます。',
                    icon: 'service_2.svg'
                },
                {
                    title: 'コールセンター',
                    info: '顧客サービス電話のリアルタイム書き起こしを行い、顧客のニーズを記録し分析しやすくし、サービスの質を向上させます。',
                    icon: 'service_3.svg'
                },
                {
                    title: '医療文書',
                    info: 'リアルタイム音声認識により、医療文書の記述効率が向上し、医療スタッフの文書作業を減らすことができます。',
                    icon: 'service_4.svg'
                },
                {
                    title: '裁判文書',
                    info: '裁判中のリアルタイム書き起こしを行い、裁判記録の正確性と完全性を確保します。',
                    icon: 'service_5.svg'
                },
                {
                    title: '教育 & トレーニング',
                    info: 'リアルタイムで字幕を生成し、学生がコース内容をよりよく理解し吸収し、学習効率を向上させます。',
                    icon: 'service_6.svg'
                },
                {
                    title: '同時通訳',
                    info: '一つの言語の音声をリアルタイムで書き起こし、別の言語に翻訳し、言語をまたがるコミュニケーションを実現します。',
                    icon: 'service_7.svg'
                },
                {
                    title: 'リアルタイム字幕',
                    info: 'ライブイベントやセミナーなどに対してリアルタイム字幕を提供し、視聴者がリアルタイムで内容を理解できるようにします。',
                    icon: 'service_8.svg'
                },
                {
                    title: '音声インタラクション',
                    info: "車載ナビゲーション、チャットアプリなど、さまざまなシナリオで音声入力を行い、手を最大限解放します。",
                    icon: 'service_9.svg'
                }
            ]
        },
        features: {
            h2: '高い正確性',
            h4: 'DolphinVoiceは、高効率で正確な音声認識技術を提供できます。'
        },
        services: {
            h2: '全プラットフォーム互換で、オンライン会議サービスを強化する',
            h4: 'いつでもどこでもサービスにアクセスし、柔軟にさまざまなシナリオに応用し、あなたのすべてのニーズを満たします。',
            lists: [
                {
                    icon: 'API.svg',
                    title: 'Cloud API',
                    info: '登録後、すぐに音声認識APIにアクセスでき、豊富なサンプルコードとドキュメントを提供しています。'
                },
                {
                    icon: 'SDK.svg',
                    title: 'On-Device',
                    info: '音声認識エンジンをiOS、Androidなどのさまざまなデバイスにデプロイでき、インターネット接続なしで音声認識を実行できます。'
                },
                {
                    icon: 'Prem.svg',
                    title: 'On-Prem',
                    info: 'お客様の独自のサーバーに音声認識エンジンをデプロイし、クラウドサーバーでのデプロイやローカルデプロイをサポートしています。'
                }
            ]
        },
        support: {
            h2: '多言語対応',
            h4: '私たちは多言語をサポートしており、あなたの音声・ビデオファイルを正確に書き起こしできます。',
            lists: [
                {
                    icon: 'languages_bg.svg',
                    title: '多言語',
                    info: '使用する言語に関わらず、あなたの会議内容を正確に書き起こしできます。'
                },
                {
                    icon: 'translation_bg.svg',
                    title: '翻訳',
                    info: '多言語で正確な書き起こし、知能翻訳で障害なし。'
                },
                {
                    icon: 'subtitles_bg.svg',
                    title: '字幕',
                    info: '正確な音声認識で、字幕を迅速に生成します。'
                },
                {
                    icon: 'code_bg.svg',
                    title: 'バイリンガル認識',
                    info: 'ネームレスなバイリンガル切り替えで、音声認識が流れがスムーズで自然です。'
                }
            ]
        },
        choose: {
            h2: 'なぜお客様に選ばれているのか？',
            h4: ' 私たちは最も強力な技術、最も価値あるサービス、最も低い価格を提供します。',
            list: [
                {
                    icon: 'choose_icon_1.svg',
                    title: '話者識別',
                    info: 'オーディオ中の話者を自動で区別し、効率的な複数人間の対話分析を実現します。'
                },
                {
                    icon: 'choose_icon_2.svg',
                    title: 'より速い音声認識',
                    info: '私たちの高効率なサービスを利用して、迅速な音声認識を実現します。'
                },
                {
                    icon: 'choose_icon_3.svg',
                    title: 'より低いコスト',
                    info: '私たちのサービスを利用することで、あなたのコストを最大限に削減することができます。'
                },
                {
                    icon: 'choose_icon_4.svg',
                    title: '多言語対応',
                    info: '多言語モデルと多言語混在話サポートを通じて、世界中のコミュニケーションを実現します。'
                },
                {
                    icon: 'choose_icon_5.svg',
                    title: 'より高い正確性',
                    info: '先進的な音声認識技術に基づいて、正確な音声書き起こしを実現します。'
                },
                {
                    icon: 'choose_icon_6.svg',
                    title: '多様なデプロイ形式',
                    info: 'クラウドAPI、オンプレミス、オフラインSDKを選んで、あなたのビジネスニーズに応えます。'
                }
            ]
        }
    },
    file: {
        p1: {
            title: '音声やビデオファイルの書き起こしで、仕事の効率を迅速に向上させる',
            text: '私たちのモデルは、さまざまな形式の音声・ビデオファイルをテキストに書き換えることをサポートしており、文書形式と字幕形式をサポートしています。',
            infos: [
                {
                    left: '正確性：',
                    right: '98％の音声認識の正確率。'
                },
                {
                    left: '速度：',
                    right: '電光石火のごとく、最短30秒で30分の音声・ビデオファイルを書き換えることができます。'
                },
                {
                    left: 'コスト：',
                    right: '他の方案に比べて、80％のコストを削減できます。'
                }
            ],
            imglists: ['TOYKO_univers.png', 'KYOTO_univers.svg', 'MUFG.svg', 'notta.svg']
        },
        speaker: '話者',
        scenarios: {
            h2: '利用シナリオ',
            h4: '録音ファイルの書き起こしサービスを利用して、ビジネス効率を向上させ、コストを削減します。',
            list: [
                {
                    icon: 'scenarios_1.svg',
                    info1: 'メディア & エンターテイメント',
                    info2: 'あなたのビデオに字幕を迅速に追加し、コンテンツのアクセシビリティと検索能力を高め、ビデオ編集の効率を向上させます。'
                },
                {
                    icon: 'scenarios_2.svg',
                    info1: '法律 & コンプライアンス',
                    info2: 'AI音声認識を利用して、通話分析、リスク分析、裁判記録を行います。'
                },
                {
                    icon: 'scenarios_3.svg',
                    info1: '教育 & トレーニング',
                    info2: '録画された講義やオンラインセミナーなどのイベントに参照テキストを追加し、検索効率を向上させます。'
                },
                {
                    icon: 'scenarios_4.svg',
                    info1: 'コールセンター',
                    info2: 'カスタマーサポートスタッフのサービス品質を監視・分析し、顧客サービス体験を改善し、同時に運営コストを削減します。'
                }
            ],
            scene: [
                {
                    icon: 'scene_1.svg',
                    info: 'インタビューの書き起こし'
                },
                {
                    icon: 'scene_2.svg',
                    info: '医療データのトランスクリプション'
                },
                {
                    icon: 'scene_3.svg',
                    info: '電話会議分析'
                },
                {
                    icon: 'scene_4.svg',
                    info: 'ポッドキャストの書き起こし'
                },
                {
                    icon: 'scene_5.svg',
                    info: 'ビデオファイルの書き起こし'
                },
                {
                    icon: 'scene_6.svg',
                    info: '音声オファイルの書き起こし'
                },
                {
                    icon: 'scene_7.svg',
                    info: '字幕生成'
                },
                {
                    icon: 'scene_8.svg',
                    info: '音声認識'
                }
            ]
        },
        models: {
            h2: '音声認識のモデル',
            h4: 'あなたに適した特定ニーズに合ったモデルを選択してください。',
            list: [
                {
                    icon: 'models_1.svg',
                    name: '急速版',
                    info1: '最も価値のある音声テキスト変換モデル',
                    info2: "最も効率的な録音ファイル書き起こしサービスを提供し、効率を倍増させ、正確率达到98%。価格は競合他社製品の30％にのみ。技術力と経済性で卓越した解決策として際立っています。"
                },
                {
                    icon: 'models_2.svg',
                    name: '通常版',
                    info1: 'ローコストな選択肢',
                    info2: '私たちのモデルは専門的にトレーニングされ、カスタムされており、正確率は98％に達し、特に特殊な業界用語に適しています。高同時アクセスサービスは企業の拡張性ニーズを満たし、経済的で実用的な選択肢のままです。'
                }
            ]
        },
        offer: {
            h2: '何を提供できるのか？',
            h4: '私たちの先進技術を利用して、音声・ビデオの書き起こしを驚くべき速度で実現します。',
            list: [
                {
                    icon: 'offer_1.svg',
                    title: '言語認識',
                    info: '強力な音声認識技術で、短時間内に音声を自動でテキストに変換できます。'
                },
                {
                    icon: 'offer_2.svg',
                    title: '多言語対応',
                    info: '音声認識モデルは20以上の言語をサポートし、多くのアクセントにも対応しています。'
                },
                {
                    icon: 'offer_3.svg',
                    title: '話者識別',
                    info: 'サービスは複数人間の会話の中での発言者の役割を検出できます。'
                },
                {
                    icon: 'offer_4.svg',
                    title: '分野別モデル',
                    info: 'サービスは多くの分野の最適化モデルを提供し、認識の正確率を向上させます。'
                },
                {
                    icon: 'offer_5.svg',
                    title: '音声・ビデオの構造化抽出',
                    info: '録音ファイルの書き起こしは、ユーザーが音声データの内容をテキストで検索するのを助けます。'
                },
                {
                    icon: 'offer_6.svg',
                    title: '句読点自動予測',
                    info: '音声・ビデオの書き起こしプロセスで、コンマ、ピリオド、疑問符、感嘆符などの句読点を追加します。'
                },
                {
                    icon: 'offer_7.svg',
                    title: 'カスタム語彙',
                    info: '人名、地名、業界用語などをホットワードとして設定し、録音ファイルの書き起こしの正確性を高めます。'
                },
                {
                    icon: 'offer_8.svg',
                    title: '書き起こしの形式',
                    info: '選択した形式（文書または字幕）に録音ファイルを書き起こします。'
                }
            ]
        },
        development: {
            h2: '柔軟なデプロイメントをサポートしています。',
            h4: '多プラットフォーム対応で、異なる顧客のニーズに応えます。',
            lists: [
                {
                    icon: 'API.svg',
                    title: 'Cloud API',
                    info: '登録後、すぐに音声認識APIにアクセスでき、豊富なサンプルコードとドキュメントを提供しています。'
                },
                {
                    icon: 'Prem.svg',
                    title: 'On-Prem',
                    info: "お客様の独自のサーバーに音声認識エンジンをデプロイし、クラウドサーバーでのデプロイやローカルデプロイをサポートしています。"
                }
            ]
        },
        
        support: {
            h2: '多言語対応',
            h4: '私たちは多言語をサポートしており、あなたの音声・ビデオファイルを正確に書き起こしできます。',
            lists: [
                {
                    icon: 'languages_bg.svg',
                    title: '多言語',
                    info: '使用する言語に関わらず、あなたのファイルの内容を正確に書き起こしできます。'
                },
                {
                    icon: 'translation_bg.svg',
                    title: '翻訳',
                    info: '多言語で正確な書き起こし、知能翻訳で障害なし。'
                },
                {
                    icon: 'subtitles_bg.svg',
                    title: '字幕',
                    info: '正確な音声認識で、字幕を迅速に生成します。'
                },
                {
                    icon: 'code_bg.svg',
                    title: 'バイリンガル認識',
                    info: 'ネームレスなバイリンガル切り替えで、音声認識が流れがスムーズで自然です。'
                }
            ]
        },
        choose: {
            h2: 'なぜお客様に選ばれているのか？',
            h4: ' 私たちは最も強力な技術、最も価値あるサービス、最も低い価格を提供します。',
            list: [
                {
                    icon: 'choose_icon_1.svg',
                    title: '話者識別',
                    info: 'オーディオ中の話者を自動で区別し、効率的な複数人間の対話分析を実現します。'
                },
                {
                    icon: 'choose_icon_2.svg',
                    title: 'より速い音声認識',
                    info: '私たちの高効率なサービスを利用して、迅速な音声認識を実現します。'
                },
                {
                    icon: 'choose_icon_3.svg',
                    title: 'より低いコスト',
                    info: '私たちのサービスを利用することで、あなたのコストを最大限に削減することができます。'
                },
                {
                    icon: 'choose_icon_4.svg',
                    title: '多言語対応',
                    info: '多言語モデルと多言語混在話サポートを通じて、世界中のコミュニケーションを実現します。'
                },
                {
                    icon: 'choose_icon_5.svg',
                    title: 'より高い正確性',
                    info: '先進的な音声認識技術に基づいて、正確な音声書き起こしを実現します。'
                },
                {
                    icon: 'choose_icon_6.svg',
                    title: '多様なデプロイ形式',
                    info: 'クラウドAPIやプライベートデプロイメントを選択して、あなたのビジネスニーズに応えます。'
                }
            ]
        }
    },
    languages: {
        p1: {
            title: 'バイリンガル音声認識',
            text: 'コミュニケーションの中で無駄なバイリンガル切り替えを行い、音声認識を流暢で自然にします。',
            imglists: ['TOYKO_univers.png', 'KYOTO_univers.svg', 'MUFG.svg', 'notta.svg']
        },
        support: {
            h2: '数十言語をサポート',
            h4: '多言語の音声認識をサポートします。',
            lists: [
                {
                    icon: 'languages_bg.svg',
                    title: '多言語',
                    info: '正確な多言語識別で、音声認識タスクを高効率に完了させます。'
                },
                {
                    icon: 'translation_bg.svg',
                    title: '翻訳',
                    info: '多言語で正確な書き起こし、知能翻訳で障害なし。'
                },
                {
                    icon: 'subtitles_bg.svg',
                    title: '字幕',
                    info: '正確な音声認識で、字幕を迅速に生成します。'
                },
                {
                    icon: 'code_bg.svg',
                    title: 'バイリンガル対応',
                    info: 'ネームレスなバイリンガル切り替えで、音声認識が流れがスムーズで自然です。'
                }
            ]
        }
    },
    translation: {
        p1: {
            title: '音声とビデオをテキストに書き起こす',
            text: '言語の壁を打ち破る：AI駆動の音声翻訳と書き起こしサービスです。'
        },
        online: {
            title: 'オンライン翻訳',
            info: '待ち時間なしのリアルタイム翻訳。当社のオンライン翻訳サービスは、複数の言語をサポートし、いつでもどこでも高品質な翻訳結果を提供します。音声をアップロードするかマイクを接続するだけでリアルタイム翻訳が可能で、コミュニケーション効率が向上します。'
        },
        punc: {
            title: '句読点予測',
            info: 'ネットワークが不安定？心配無用！当社のオフライン翻訳機能により、ネットワークに依存せずにいつでも翻訳が可能です。必要な言語パックを事前にダウンロードすることで、オフライン環境でも迅速かつ正確な翻訳結果を得られ、いつでもどこでも障害のないコミュニケーションを保証します。'
        },
        languages: {
            h2: '多言語翻訳サポート',
            h4: '私たちは数十の言語での書き起こしと翻訳機能をサポートし、ビジネスの成功を保証します。'
        },
        exper: {
            title: '翻訳におけるスピード、精度、信頼性を体験',
            info: "翻訳において、正確性と信頼性は譲れないポイントです。最先端のAIと機械学習技術を使用することで、最高品質が保証されます。当社の音声認識ソフトウェアは、音声やビデオを労力をかけずに書き起こし、手動での書き起こしの面倒な作業からあなたを解放します。完璧な精度を求める場合は、必要に応じてテキストを調整して微調整してください。"
        },
        ideal: {
            title: 'YouTube、ポッドキャスト、インタビュー、ビジネスミーティングに最適',
            info: "私たちの書き起こしサービスは、Spotifyのポッドキャスト、インタビュー、スピーチなど、さまざまなオーディオやビデオコンテンツを書面に変換することに優れています。また、YouTubeやTikTokのビデオクリエイターにも対応しています。字幕を追加してビデオコンテンツを強化し、より広い視聴者層にリーチしましょう。さらに、書き起こしはブログや記事へのコンテンツの再利用の道を開きます。即時ビデオ翻訳も利用可能です。"
        },
        customization: {
            title: '完全にカスタマイズされた翻訳、あなたのニーズに合わせて',
            info: "当社のサービスは柔軟なカスタマイズを提供し、あなたのオーディオ翻訳が要件に完全に合致することを保証します。中国語、日本語、韓国語からスペイン語、アメリカ英語、イギリス英語まで、20以上の言語にメディアを翻訳する能力を持っており、あなたをサポートします。さらに、オーディオをテキストに書き起こし、字幕を追加して、コンテンツを全世界にアクセスしやすくします。"
        }
    },
    proDevice: {
        p1: {
            title: 'インテリジェント音声インタラクションオフラインSDKソリューション',
            text: '先進技術を活用して、リソース消費が少なく、多言語対応かつデバイス端での計算が可能なスマート音声インタラクションを実現し、エッジコンピューティングシナリオのニーズに応えながら、プライバシーとセキュリティを確保します。'
        },
        advantages: {
            h2: 'オフラインSDKの利点',
            h4: 'スマートフォン、通訳機、ペン型スキャナー辞書など、携帯性のあるデバイスに適しており、インターネット接続なしで高効率な音声インタラクション機能を実現できます。',
            list: [
                {
                    icon: 'advantage_1.svg',
                    title: '優れたパフォーマンス',
                    info: '先進のモデル精製アルゴリズムを採用し、モバイル端末の低計算能力デバイスでも同様に優れた効果を保証します。'
                },
                {
                    icon: 'advantage_2.svg',
                    title: '簡素化・最適化',
                    info: '高効率の圧縮アルゴリズムを採用し、モデルを10％に圧縮し、最小で50MBに圧縮でき、モバイルデバイスに適しています。'
                },
                {
                    icon: 'advantage_3.svg',
                    title: '永続ライセンス',
                    info: '一度の認証で永続的に使用でき、さまざまなシナリオのニーズに柔軟に応えます。'
                }
            ]
        },
        program: {
            h2: 'ソリューションアーキテクチャ',
            programImg: 'program_image_ja.png',
            h4: '私たちのオフラインSDKソリューションは柔軟であり、さまざまな複雑な要件にも対応できます。'
        },
        offline: {
            title: 'オフライン機能',
            info: 'SDKをモバイルデバイスのアプリに統合し、すべての機能をオフラインで呼び出します。初回使用時に一度インターネット接続を利用してアクティベーションを行い、その後はすべての機能を永久にオフラインで使用でき、海外出張のようなインターネット接続がない状況でもオフライン機能をサポートします。データセキュリティに特に高要求のお客様は、インターネット接続なしのアクティベーションオプションを選択し、プロセス全体でインターネットに接続せず、ユーザーデータの絶対的な安全を確保することができます。'
        },
        integration: {
            title: '簡単に導入',
            info: 'Android、iOSなどの主要プラットフォームをサポートし、機能が充実したSDKと詳細な開発ドキュメントおよびサンプルコードを提供し、二度目の開発を容易にします。複数の言語に対応するモデルを同時に読み込むことで、モデル切り替えによる時間のロスを回避します。'
        },
        config: '仕様推奨でコストを低減する',
        configImg: 'config_image_ja.png'
    },
    proPrem: {
        p1: {
            title: 'オンプレミス、安全かつ管理可能、個性化対応のカスタマイズをサポート',
            text: 'オンプレミスはデータの安全性を保証し、高可用性サービスを提供し、柔軟なカスタマイズをサポートして、企業の特殊なニーズに応えます。'
        },
        state: {
            h2: '音声インタラクション業界の現状',
            h4: '技術的瓶頸が頻発しており、ユーザー体験の改善が必要です。',
            list: [
                {
                    icon: 'state_1.svg',
                    text: 'セキュリティ要件が高い業界では、公有クラウドサービスではニーズを満たせません。'
                },
                {
                    icon: 'state_2.svg',
                    text: '従来の解決策ではオフライン使用シナリオのニーズを満たすことが難しいです。'
                },
                {
                    icon: 'state_3.svg',
                    text: '言語サポートの範囲が限られており、多言語シナリオに対応するのが難しいです。'
                },
                {
                    icon: 'state_4.svg',
                    text: 'オンプレミスのコストが高いため、カスタマイズニーズを満たすことが難しいです。'
                },
                {
                    icon: 'state_5.svg',
                    text: 'ビジネス分野には専門用語が多く含まれており、汎用モデルの効果は理想ではありません。'
                },
                {
                    icon: 'state_6.svg',
                    text: '配置とアクセス操作が複雑で、ユーザーが二次開発を行うことが困難です。'
                }
            ]
        },
        advantages: {
            h2: '当社のオンプレミスの優位性',
            h4: '配置が簡単で迅速で、安全かつ信頼性があり、多様なシーンのニーズに応え、企業のデジタル化発展を支援します。',
            data: {
                title: 'データのセキュリティとコントロールを確保する',
                info1: "Dolphin Voiceが提供するプライベート配置方案では、企業のデータセキュリティを確保し、ユーザーのプライバシーを最大限に守ることができます。",
                info2: '私たちはローカルサーバーやAWS EC2/Azure VMなどのパブリッククラウドでの配置をサポートし、柔軟なアーキテクチャ設計、最適なリソース割り当て、システム性能の向上と拡張性を図ります。'
            }
        },
        easy: {
            title: 'デプロイが簡単で、ハードウェアの構成要件が低い',
            info1: '私たちの解決策は配置が簡単かつ迅速で、設定要求が低く、多種のプラットフォーム環境をサポートし、企業の配置負担を軽減します。',
            info2: '深層加速最適化：GPUへの依存を解消し、モデルを簡素化。音声認識の単一言語モデルは約2-3GBで、メモリ使用量が少なく、一般的なハードウェア構成で実行要件を満たすことができます。'
        },
        sdk: {
            title: '豊富なSDKで速い導入が可能',
            info1: '私たちは主流の開発言語をサポートする豊富なSDKリソースを提供し、企業が自分のアプリケーションに迅速に統合するのに便利です。',
            info2: 'Python、Java、C++、JavaScriptなどの主流プログラミング言語のSDKを提供し、開発者がアプリケーションを迅速に構築するのに役立ちます。',
        },
        langs: {
            title: '多言語サポートで国際化ニーズに対応',
            info1: "私たちの音声認識技術は日本語、英語、中国語（標準語）など、世界中で20以上の言語をサポートしており、さまざまなインタラクションシナリオに適しています。",
            info2: "開発者は音声認識サービスに迅速にアクセスし、さまざまな音声インタラクション製品を構築し、世界中のシームレスな体験を保証できます。"
        },
        flexible: {
            title: '優れた柔軟性と拡張性',
            info1: '私たちの解決策は配置が簡単かつ迅速で、設定要求が低く、多種のプラットフォーム環境をサポートし、企業の配置負担を軽減します。',
            info2: '柔軟なSDKとAPIを提供し、主流の開発言語をサポートして、二次開発を容易にします。'
        },
        offline: {
            title: 'プライベートネットワークでの呼び出し、企業データセキュリティ専用に',
            info1: 'DolphinVoiceの音声認識技術は、専用クラウドのオフライン呼び出しをサポートし、データセキュリティのシナリオに特化して設計されています。私たちの音声処理アルゴリズムと事前トレーニングされた言語モデルにより、サーバーはインターネットアクセスなしでローカルで音声認識タスクを処理することができます。',
            info2: 'この機能は、特にプライバシー保護の要求が高い政治企業ユーザーに適しており、法律文書の処理、医療文書の入力、会議録などがあります。プライベート配置サービスを通じて、デバイスはインターネットへの依存を解消し、信頼性とリアルタイム性を高め、ユーザーによりスムーズで安定した体験を提供します。'
        },
        support: {
            h2: '多様なデプロイ方法をサポート',
            h4: 'DolphinVoiceは柔軟な技術アーキテクチャを提供し、多様なデプロイ方法をサポートします。',
            computing: {
                title: '柔軟なデプロイで、高性能コンピューティングを実現',
                computingImg: 'computing_image_ja.png',
                info1: '単一サーバー（またはコンピューティングクラスター）でのデプロイをサポートし、既存のサーバー（クラスター）の性能を最大限に活用します。ユーザーはビジネスニーズに基づいて異なるビジネスモジュール、インスタンス数などを選択できます。'
            }
        },
        lightweight: {
            lwImg: 'lightweight_image_ja.png',
            title: 'ポータブルなインテリジェント音声プラットフォームの軽量デプロイ',
            info1: 'ノートパソコン、デスクトップPC、OPSホストなどのデバイスでデプロイをサポートし、軽量のインテリジェント音声インタラクションコンピューティングプラットフォームを構築し、高ポータビリティのAIサービスを実現し、データセキュリティを確保します。システムはLinux/Windowsプラットフォームでのデプロイをサポートし、ユーザーはシーンのニーズとホストの性能に基づいて、各機能モジュールを自由に有効化または無効化して、サービスコストを削減し、計算効率を向上させ、使用コストを節約します。'
        },
        cost: {
            costImg: 'cost_image_ja.png',
            title: '仕様推奨でコストを低減する',
            info1: '軽量デプロイや高性能コンピューティングなど、さまざまなシナリオに適した一連の仕様推奨案を提供しています。異なるアプリケーションのニーズとビジネス規模に応じて、適切な最適化された仕様の提案も行っています。ユーザーは実際の状況に応じて柔軟に選択や調整を行って、ビジネスの効率的な運営を保証できます。'
        },
        pss: {
            h2: '利用シナリオ',
            h4: '高精度の音声認識で、応用範囲が広く、顧客のニーズを全面的に満たします。',
            list: [
                {
                    icon: 'service_1.svg',
                    title: '音声アシスタント',
                    info: '車載ナビゲーション、スマートスピーカーなど、さまざまなシーンで音声入力を行い、手を最大限に解放します。'
                },
                {
                    icon: 'service_2.svg',
                    title: '音声ファイルの書き起こし',
                    info: '会議やインタビューなどの音声・ビデオファイルを迅速に文書（または字幕）に書き換えます。'
                },
                {
                    icon: 'service_3.svg',
                    title: 'カスタマーサービス',
                    info: '顧客サービス電話を高速で書き写し、顧客のニーズを記録し分析しやすくなり、サービスの質を向上させます。'
                },
                {
                    icon: 'service_4.svg',
                    title: 'オーディオブック',
                    info: '機械が感情豊かな声で文学作品を朗読させ、目を休めている間にも声の美しさを感じさせます。'
                },
                {
                    icon: 'service_5.svg',
                    title: 'リアルタイム字幕',
                    info: 'ライブスピーチやライブ配信のビデオの音声をリアルタイムで字幕に変換し、さらに内容を翻訳などの処理を行うことができます。'
                },
                {
                    icon: 'service_6.svg',
                    title: '教育とトレーニング',
                    info: '録画された講義やオンラインセミナーなどのイベントに参照テキストを追加し、検索効率を向上させます。'
                }
            ]
        },
        concat: {
            left: {
                text1: '今すぐ体験版の申し込みを',
                text2: '申請を提出した後、あなたはテストのためにデプロイに使用されるインストールリソースパッケージを受け取ります。'
            },
            tips: {
                errorEmail: '正しいメールアドレスの形式で入力して下さい',
                empty: 'この項目は空にすることはできません',
                selectTip: '選択してください'
            },
            formBtn: '送信',
            form: [
                {
                    icon: 'email_icon.svg',
                    text: 'メールアドレス *'
                },
                {
                    icon: 'name_icon.svg',
                    text: '名前 *'
                },
                {
                    icon: 'phone_icon.svg',
                    text: '携帯'
                },
                {
                    icon: 'job_icon.svg',
                    text: '職位'
                },
                {
                    icon: 'organization_icon.svg',
                    text: '会社名 *'
                },
                {
                    icon: 'belong_icon.svg',
                    text: '業界'
                },
                {
                    icon: 'intended_icon.svg',
                    text: '使用シナリオ *'
                }
            ],
            belongList: [
                '情報通信・インターネット',
                '金融・法人サービス',
                '娯楽・エンタメ・メディア',
                '自動車・機械',
                '建設・不動産',
                '運輸・物流',
                '流通・外食',
                '政府・公共サービス',
                '食品・農業',
                '資源・エネルギー・素材'
            ]
        },
        // activate: {
        //     h2: 'Activate Trial Service',
        //     h4: 'まず、体験申請を提出してサービスをデプロイする必要があり、アクティベーション後、30日間無制限の完全な機能体験を享受できます。',
        //     btn: 'Activate',
        //     inputTip: 'Please enter your serial number',
        //     list: [
        //         {
        //             icon: 'resource_icon_1.svg',
        //             title: '资源下载',
        //             info: 'Download the latest SDKs, sample code, and technical documentation to help you quickly develop voice interaction applications.',
        //             filePath: ''
        //         },
        //         {
        //             icon: 'resource_icon_2.svg',
        //             title: '查看技术文档',
        //             info: 'We provide detailed API reference manuals, integration guides, and best practices to fully support your development and integration work.',
        //             filePath: 'api/intro/notice.md'
        //         }
        //     ]
        // }
    },
    API: {
        p1: {
            title: 'コスト効率の良いオープンクラウドAPIサービス',
            text: 'Dolphin Voiceプラットフォームは高品質のクラウドベースのAIサービスを提供し、企業や個人ユーザーがスマート音声インタラクション機能を迅速に統合するのを支援し、月々の使用量に応じた自動請求システムにより、お客様はサーバーのメンテナンスコストを一切負担する必要がありません。'
        },
        features: {
            h2: 'プロダクトの特徴',
            h4: '技術の先進性、強力な製品体験、一流のサービス能力。',
            list: [
                {
                    icon: 'features_1.svg',
                    title: '豊富な機能',
                    info: '多様なスマート音声インタラクション能力をサポートし、異なるシナリオでのビジネスニーズに対応します。'
                },
                {
                    icon: 'features_2.svg',
                    title: '即時の応答',
                    info: '呼び出し結果はリアルタイムで返され、応答速度は最速で0.1秒に達できます。'
                },
                {
                    icon: 'features_3.svg',
                    title: '簡単に導入',
                    info: '豊富なSDKおよびAPIインターフェースドキュメントを提供し、サービスの接続は簡単で初心者にもやさしい。'
                },
                {
                    icon: 'features_4.svg',
                    title: '高い信頼性',
                    info: '業界をリードするクラウドコンピューティングプラットフォームを基に、24時間365日サービスを提供します。'
                }
            ]
        },
        advantages: {
            h2: 'Cloud API ソリューションの利点',
            h4: '最小限の開発で最も強力な機能を統合し、業界をリードする音声インタラクション技術を体験します。',
            list: [
                {
                    icon: 'advantage_1.svg',
                    title: '自動スケーリング',
                    text: '需要に応じて柔軟な計算、ストレージ、ネットワークリソースを提供し、リソースの規模を柔軟に拡大または縮小し、ハードウェアの拡張や最適化に大量の資本や人力资源和を投入する必要がありません。'
                },
                {
                    icon: 'advantage_2.svg',
                    title: '多様な料金体系',
                    text: '従量課金により、お客様は実際の使用状況に基づいてサービスの選択と支払いを柔軟に行い、事前の予算や大量の資金の投入は必要ありません。'
                },
                {
                    icon: 'advantage_3.svg',
                    title: '高可用性とDR（ディザスターリカバリー）',
                    text: '複数の地域、可用性ゾーン、ノードでのデプロイメントにより、ビジネスの継続的な稼働を保障します。'
                },
                {
                    icon: 'advantage_4.svg',
                    title: 'セキュリティと信頼性',
                    text: 'プロフェッショナルなセキュリティチームと技術、ISO 27001情報セキュリティ体系认证を取得し、データの安全と信頼性を確保します。'
                }
            ]
        },
        sdks: {
            h2: 'サポートされるSDK',
            h4: '統合が容易で、開発がシンプルで、サービスの迅速な統合が可能です。'
        }
    },
    contact: {
        leftText: 'お問い合わせフォーム',
        contactForm: {
            emptyText: 'この項目は空にすることはできません',
            btn: '送信',
            name: {
                label: '名前*',
                icon: 'name_icon.svg'
            },
            email: {
                label: 'メールアドレス*',
                icon: 'email_icon.svg'
            },
            tel: {
                label: '電話番号*',
                icon: 'phone_icon.svg'
            },
            company: {
                label: '会社名*',
                icon: 'organization_icon.svg'
            },
            project: {
                label: '部署名',
                icon: 'belong_icon.svg'
            },
            note: '問い合わせ事項*'
        }
    },
    security: {
        p1: {
            title: '信頼性の高いセキュリティシステム',
            text: 'お客様が安心してDolphinVoiceをご利用いただけるよう、情報セキュリティや暗号化技術を含む包括的なセキュリティシステムを構築しています。<br/>お客様のデータの所有権と管理権はすべてお客様ご自身にあります。業界をリードする存在となることを目指し、当社のサービスが効率的であると同時に、法的および業界基準に準拠していることを保証する厳格なコンプライアンス監査システムを確立しています。'
        },
        data: {
            h2: '企業レベルのデータセキュリティ',
            list: [
                {
                    title: 'ISO 27001',
                    info: 'ISO 27001 とは、国際標準化機構と国際電気標準会議共同開発された情報セキュリティマネジメントシステムISMSです。組織が情報資産の保護を適切に行い、リスクを適切に管理するためのフレームワークを提供し、情報の「機密性」「完全性」「可用性」を保護することを示します。',
                    btn: 'Detail',
                    icon: 'ISO_blue.png'
                },
                {
                    title: 'GDPR',
                    info: 'EU一般データ保護規則（General Data Protection Regulation、以下GDPR）とは、欧州経済領域（EEA）における個人情報の取り扱いについて法的要件を定めた規則です。個人情報とプライバシー保護の強化を目的としています。',
                    icon: 'gdpr.svg'
                },
                {
                    title: 'APPI',
                    info: 'APPI（個人情報保護法）は、日本国民の個人情報を保護するために制定された法律であり、企業が顧客情報などの個人情報を扱う際に権利を保護する目的を持っています。企業が個人情報を第三者に提供する際に同意を得て記録することが義務付けられています。',
                    icon: 'appi.svg'
                },
                {
                    title: 'SSL',
                    info: 'SSL（Secure Sockets Layer）とは、インターネットなどのIPネットワークでデータを暗号化して送受信するプロトコルの一つでデータ通信を暗号化し、中継装置などネットワーク上の他の機器から通信内容を覗き見たりすり替えたりできないようにする。',
                    icon: 'ssl.svg'
                }
            ]
        },
        tech: {
            h2: '技術対策',
            list: [
                {
                    title: 'データバックアップと災害復旧',
                    info: 'ドルフィンエーアイは、定期的にデータバックアップを行い、予期せぬ事態に備えてバックアップデータを安全に保管しています（日本国内大手クラウドプラットフォームのサーバーなど）。また、災害復旧計画を策定しており、災害時には迅速にバックアップシステムやデータセンターに切り替える体制を整えています。',
                    icon: 'tech_1.png'
                },
                {
                    title: 'パスワード管理ポリシー',
                    info: 'ドルフィンエーアイは、パスワードをハッシュアルゴリズムで処理し、ユーザーパスワードを転送または保存することはありません。データセンターに保存される音声やテキストなどのデータは標準で暗号化されています。ユーザーデータは匿名化されており、閲覧や利用はできません。',
                    icon: 'tech_2.png'
                },
                {
                    title: 'データ転送の暗号化',
                    info: 'ドルフィンエーアイは、安全なプロトコル（HTTPS）を使⽤し、ユーザーとサービス間のデータ転送を暗号化しています。データが盗まれたり改ざんされたりしないようにし、転送データも対称鍵暗号と⾮対称鍵暗号を組み合わせて暗号化しています。',
                    icon: 'tech_3.png'
                },
                {
                    title: 'システム運⽤・監視',
                    info: 'ドルフィンエーアイは、厳格な監視システムを導入し、データ処理の性能と状態をリアルタイムで監視しています。トラフィック、処理時間、エラー率などの主要指標を追跡し、適切な警告メカニズムを設定しています。これにより、潜在的な問題や異常を迅速に発見し、対応できます。',
                    icon: 'tech_4.png'
                }
            ]
        },
        operate: {
            h2: '運営対策',
            list: [
                {
                    title: 'セキュリティ管理',
                    info: 'ドルフィンエーアイは、厳格なセキュリティ対策を講じており、身分確認、アクセス制御、権限管理などのメカニズムを採用してデータ処理の権限を制限し、データのアクセスと操作を監視および監査しています。',
                    icon: 'operate_1.png'
                },
                {
                    title: 'セキュリティ監査',
                    info: 'ドルフィンエーアイは、システムとユーザーの活動、機密データのアクセスおよび操作を監視し記録するセキュリティ監査メカニズムを整備しています。また、セキュリティモニタリングシステムを導入し、潜在的なセキュリティイベントや侵入行為に迅速に対応しています。',
                    icon: 'operate_2.png'
                },
                {
                    title: 'テストと検証',
                    info: 'ドルフィンエーアイは、各バージョンのリリース前に、単体テスト、結合テスト、システムテストなどの詳細なテストと検証を行い、あらゆる状況でデータ処理システムの信頼性と安定性を確保しています。',
                    icon: 'operate_3.png'
                },
                {
                    title: 'セキュリティ教育',
                    info: 'ドルフィンエーアイは、定期的に社内でセキュリティ研修を実施し、最適なセキュリティプラクティス、潜在的脅威の特定、データ保護の重要性について教育しています。また、ユーザーには定期的なパスワード変更や共有デバイスでのログイン回避など、積極的なセキュリティ対策を推奨しています。',
                    icon: 'operate_4.png'
                }
            ]
        }
    },
    isms: {
        h2: 'ISMS 認証(ISO 27001)を取得しました!',
        text1: '株式会社ドルフィンエーアイは、国際規格「ISO/IEC 27001:2022」の情報セキュリティマネジメントシステム（ISMS）認証を2024年06月06日に取得いたしました。',
        text2: 'ISO/IEC 27001は、情報セキュリティマネジメントシステム（ISMS）に関する国際規格です。情報の機密性・完全性・可用性の3つをバランスよくマネジメントし、情報を有効活用するための組織の枠組みを示しています。',
        text3: '今回の認証取得により、当社は情報セキュリティをさらに強化し、情報資産を正確かつ安全に取り扱い、経営戦略に合致した情報セキュリティの実現を目指します。ドルフィンエーアイは厳密なセキュリティ対策と管理体制の下で、お客様安心・安全で弊社サービスご利用いただくように引き続き精進してまいります。',
        tableData: [
            {
                label: '登録組織',
                text: '株式会社ドルフィンエーアイ'
            },
            {
                label: '適用規格',
                text: 'ISO/IEC 27001:2022'
            },
            {
                label: '初回認証日',
                text: '2024年06月06日'
            },
            {
                label: '有効期限',
                text: '2027年06月05日'
            },
            {
                label: '認証範囲',
                text: 'SaaS音声AIプラットフォームの開発と技術サービス',
            },
            {
                label: '認証番号',
                text: 'N.CN24-12512D'
            }
        ]
    },
    blog: {
        title: 'Dolphin ブログ',
        info: '私たちはDolphinVoiceに関する最新機能と推薦情報を紹介します。',
        author: '作 者'
    },
    errorTips: {
        lackPermission: 'Lack of recording permission',
        notFound: 'No recording device found',
        errorWarning: 'Unexpected error:',
        discarded: 'Encoding queue is blocked, one frame has been discarded.',
        stopInfo: ':Transmission has been stopped',
        wsWarning: '接続失敗',
        copy: 'コピー成功'
    },
    features: {
        btn: 'More than 20 Languages',
        p1: {
            title: '先進のAI音声技術でプロダクト体験を向上させる',
            text: 'Dolphin Voiceの多種のAI音声製品を利用して、あなたの製品のユーザー体験を向上させます。私たちの製品は強力な性能と包括的な機能を持ち、同時に多様なデプロイメント方案を提供できます。',
            h2: '個人化ニーズに応える機能'
        },
        mutiLanguage: {
            title: '多言語対応',
            info: '私たちの音声認識技術は日本語、英語、中国語標準語など、世界中の20以上の言語をサポートしています。ユーザーはどこにいても、便利な自然な音声インタラクション体験を享受できます。強力な多言語モデルにより、あなたの製品は異なる国と地域のユーザーニーズに応え、グローバルサービスを実現します。'
        },
        codeSwitching: {
            title: 'バイリンガル認識',
            info: 'ネームレス言語切り替えと適応型音声認識を探求します。私たちの技術はバイリンガル混在の音声認識を容易に行い、バイリンガルインタラクションを正確に捉え、世界中のビジネスと教育に非常に適しており、世界と簡単につながります。'
        },
        resourceSaving: {
            title: 'リソース節約',
            resourceImg: 'resource_saving_ja.svg',
            info: '私たちの高効率アルゴリズムは、高価な GPU 不要で CPU 上で最適なパフォーマンスを実現することを目的としています。これにより、展開がより簡単かつ経済的になります。スピードと正確性を保証しつつ、さまざまなアプリケーションに適用できます。先進技術を利用しながらコストを管理しようとする企業に非常に適しています。'
        },
        captioning: {
            title: 'リアルタイム字幕',
            info: '音声認識技術により、即時に明瞭で正確な字幕を提供し、異なる環境下の多様なニーズに対応できます。会議、セミナー、またはライブストリーミングコンテンツにかかわらず、私たちの解決策はアクセシビリティを確保し、視聴者の参与度を高めます。'
        },
        characteristic: {
            title: '高度な特徴',
            info: '音声認識システムは、多様なアプリケーションシナリオに適応する強化機能を提供します。ライブイベントのリアルタイム書き起こし、スマートデバイスの音声制御、カスタマイズされた仮想アシスタントを使用したカスタマーサービスなどが含まれます。ノイズ適応や方言認識などの高度な機能により、さまざまな環境における音声認識の正確性要件を満たすことができます。'
        },
        fullLink: {
            title: 'フルリンク',
            info: 'Dolphin Voiceのフルリンクソリューションは、音声インタラクションのすべての機能をカバーし、さまざまなアプリケーション間で高い正確性と可用性を確保し、お客様に強力なワンストップ技術ソリューションを提供します。私たちの先進的なシステムを使用して、プロセスを簡素化し、機能を強化します。'
        },
        offer: {
            h2: '何を提供できるのか？',
            offerImg: 'Batch_Transcription_ja.png',
            h4: '最先端の音声インタラクション技術を利用して、最もシンプルなワークフローで価値を最大限に引き出します。',
            info1: {
                title: 'リアルタイム音声認識',
                text: 'リアルタイム音声ストリームに対して即時の正確な音声認識を行い、長い発言を迅速かつ正確に書き起こし、先進的な音声認識技術でコミュニケーション方法を根本的に変革します。'
            },
            info2: {
                title: '録音ファイル書き起こし',
                text: '大量に事前に録音された音声・ビデオファイルを簡単に書き起こし、音声コンテンツを構造化されたテキスト情報に変換し、後続の検索などのさらなる処理を容易にします。'
            }
        },
        values: {
            h2: '卓越した性能',
            valuesImg: 'values_ja.png',
            h4: 'クラウドAPI、オンデバイス、オンプレミスのソリューションはさまざまな顧客のニーズに対応できます。実際のシナリオに基づいて適切なソリューションを選択し、最適な体験を得ます。'
        },
        needs: {
            h2: '多様なソリューションを通じて異なるニーズに応える',
            h4: 'クラウドAPI、オンデバイス、オンプレミスのソリューションはさまざまな顧客のニーズに対応できます。実際のシナリオに基づいて適切なソリューションを選択し、最適な体験を得ます。',
            info1: {
                title: 'Cloud APIソリューション：高速で安全でスケーラブル',
                text: '私たちが提供するAPIとSDKを利用することで、あなたの独自のアプリケーションに急速に私たちのサービスを統合できます。即時の利点には、最新の機能、言語、更新へのアクセスが含まれており、あなたの解決策が常に革新の最前線に位置することを保証します。サーバーリソースに関心を持ちません。使用したサービスに対してのみ支払います。'
            },
            info2: {
                title: 'On-Premソリューション：データセキュリティを確保する',
                text: 'AIエンジンを独自のサーバーに展開し、先進的な機能を享受しながら、厳格なセキュリティとコンプライアンス基準を満たします。展開は簡単で、ハードウェアの要求は低く、GPUへの依存はなく、ユーザーのコストを大幅に削減します。高並発、高可用性の解決策を提供し、柔軟性により実際のシーンのニーズに対応します。'
            },
            info3: {
                title: 'On-Deviceソリューション：モバイルデバイスのコンピューティングパワーを十分に活用する',
                text: 'モバイル向けの解決策により、お客様のエンドデバイス上で先進的な音声認識エンジンを直接実行し、すべての機能のオフライン利用を実現できます。初回使用時に一度インターネット接続を利用してアクティベーションを行い、すべての機能を永久にオフラインで使用でき、海外出張などのインターネット接続がない状況でもオフライン機能を提供します。データセキュリティに特に高要求のお客様は、インターネット接続なしのアクティベーションオプションを選択でき、プロセス全体でインターネットに接続せず、ユーザーデータの絶対安全を確保します。'
            }
        }
    },
    backend: {
        logout: 'サインアウト',
        telFormat: '半角ハイフンと半角数字の形式で入力してください',
        login: {
            title1: 'ログイン',
            title2: 'パスワードをお忘れですか？',
            title3: 'パスワードリセット',
            form1: {
                mail: 'メールアドレスを入力してください',
                pwd: 'パスワードを入力してください'
            },
            form2: {
                label1: '新しいパスワードを入力してください',
                label2: 'パスワードをご確認ください',
                placeholder1: '8～16文字の英数字と記号を含むパスワードを入力してください',
                placeholder2: 'パスワードを再度ご確認ください'
            },
            btn1: 'ログイン',
            btn2: '送信',
            btn3: '送信',
            btn4: '新規登録', 
            btn5: 'パスワードをお忘れですか？',
            tips: {
                sendTip: '登録したメールアドレスを入力してください。<br/>新しいパスワードを設定するためのURLをお送りします。',
                accountError: "We can't seem to find your account.,Please re-enter or sign up.",
                pwdError: '正しいメールアドレスまたはパスワードを入力してください。',
                noAccount: 'メールアドレスが登録されていないため、パスワード再設定メールの送信が失敗しました。',
                formatErr: '8～16文字の英数字と記号を含むパスワードを入力してください',
                inconsistency: '登録されたパスワードと一致しません'
            }
        },
        register: {
            title1: "新規登録",
            title2: '必要な情報を提供してください。',
            title3: '登録情報を確認し、間違いがなければ「次へ」、変更が必要であれば「戻る」をクリックしてください。',
            tips: {
                agree1: 'の内容を確認＆同意お願いします。',
                agree2: '利用規約',
                agree3: 'と',
                agree4: 'プライバシーポリシー',
                account1: 'すでにアカウントをお持ちの方は',
                account2: 'ログイン',
                account3: 'してください。',
                send: 'へメールを送信しました',
                list: [
                    '受信したメール本文中のURLから登録情報の補足を行ってください。',
                    '※ URLの有効期限は24時間です。有効期限を過ぎた場合は利用申し込みからやり直してください。',
                    '※ しばらくたってもメールが届かない場合は、入力したメールアドレスに間違いがないかをご確認いただくか、再度登録手続きを行ってください。'
                ],
                success1: '登録が完了しました。',
                success2: 'してください。',
                error: 'リンクの有効期限が切れていますので、再度お申し込みください。',
                agreeTip: 'サービス規約ご確認と同意下さい',
                emailTip: '形式が正しいメールアドレスを入力して下さい'
            },
            swiper: [
                {
                    line1: '①<br/>新規登録',
                    line2: '有効なメールアドレスを利用して新規登録とメール検証してください。'
                },
                {
                    line1: '②<br/>クレジットカードを登録してください',
                    line2: 'クレジットカード登録でフルサービスを利用してください。'
                },
                {
                    line1: '③<br/>API連携',
                    line2: '毎月の無料枠は60分になります。'
                }
            ],
            formLabel: [
                'メールアドレス：',
                'パスワード：',
                'パスワード(確認)：',
                '苗字：',
                '名前：',
                '電話番号：',
                '会社名：',
                '郵便番号：',
                '住所：',
                '部署名：',
                '役職：',
            ],
            btn1: '新規登録',
            btn2: '送信',
            btn3: '戻る',
            btn4: '次へ',
            btn5: 'ログイン'
        },
        menu: [
            // {
            //     name: 'クレジットカード登録',
            //     icon: 'card.svg',
            //     activeIcon: 'card_active.svg',
            //     path: '/backend/'
            // },
            {
                name: '利用量',
                icon: 'used.svg',
                activeIcon: 'used_active.svg',
                path: '/backend'
            },
            {
                name: 'エンジン一覧',
                icon: 'engine.svg',
                activeIcon: 'engine_active.svg',
                path: '/backend/engine'
            },
            {
                name: '接続情報',
                icon: 'pro_info.svg',
                activeIcon: 'pro_info_active.svg',
                path: '/backend/informations'
            },
            // {
            //     name: 'エラー集計',
            //     icon: 'error.svg',
            //     activeIcon: 'error_active.svg',
            //     path: '/backend/'
            // },
            {
                name: '単語登録',
                icon: 'term.svg',
                activeIcon: 'term_active.svg',
                path: '/backend/term'
            },
            // {
            //     name: '料金内訳',
            //     icon: 'cost.svg',
            //     activeIcon: 'cost_active.svg',
            //     path: '/backend/costdetails'
            // },
            {
                name: 'マイページ',
                icon: 'user.svg',
                activeIcon: 'user_active.svg',
                path: '/backend/usercenter'
            }
        ],
        used: {
            h2: '月間利用状況明細',
            info1: '利用状況は',
            info2: '時点までのものです。 表示価格は税抜きです。',
            tabNames: ['Cloud API機能'],
            monthTip: '利用月',
            offlineMon: 'ご請求月',
            onLineTableTitles: ['エンジン一覧', '単価', '利用時間（秒）', '有料時間（秒）', '金額（税抜、円）'],
            offLineTableTitles: ['エンジン一覧', '単価', '使用量（台）', '付费量（台）', '金額（税抜、円）'],
            billingMethod: [
                '従量課金<br/>(',
                '分まで無料、それ以降は',
                '円/秒)',
                '分',
                '台数課金<br/>(',
                '台まで無料、それ以上は',
                '円/台)',
                '台',
                '無料利用時間なし，'
            ],
            saveType: [
                '（ログ保存なし）',
                '（ログ保存あり）',
            ]
        },
        engine: {
            h2: 'エンジン一覧',
            tabNames: ['Cloud API機能'],
            info: '*「ログ保存あり」の場合、月々xx分まで無料でご利用いただけ、保存された音声は弊社製品・サービスの研究開発・品質向上に活用されます；<br/>*「ログ保存なし」の場合、音声や認識結果はサーバーに保存されません；<br/>* 表示金額は税抜きです。',
            cardsType: ['ログ保存あり', 'ログ保存なし'],
            btn: '詳細',
            onlineInfo: {
                line1: '無料利用時間',
                line2: '単価',
                line3: '割引価格',
                unit1: '分',
                unit2: '円/秒',
                unit3: 'なし'
            }
        },
        project: {
            h2: '接続情報',
            info: '*接続できない、認識結果が取得できないなどの問題が発生した場合は、',
            questions: 'FAQ',
            punctuation: 'をご確認ください。',
            title1: '一般プロジェクト',
            createBtn :'新規プロジェクト',
            tip: '*APPIDとAPPSecretはサービス接続のために使用されます。違う製品目的ごとに複数のプロジェクトを作成することができ、複数プロジェクトの使用状況は合計で表示され、決済されます。',
            list: {
                name: 'プロジェクト名',
                appId: 'APPID',
                appSecret: 'APPSecret',
                desc: 'プロジェクト説明'
            },
            title2: 'エンジン別の接続情報',
            tableTitle: ['エンジン', 'HTTP音声認識API', 'WebSocket音声認識API'],
            file: ['書き起こしのリクエスト', '書き起こし結果の取得 ', 'タスク照会'],
            tableData: [
                {
                    name: '一発話認識',
                    httpUrl: 'https://api.voice.dolphin-ai.jp/v1/asr/api',
                    wsUrl: 'wss://api.voice.dolphin-ai.jp/v1/asr/ws'
                },
                {
                    name: 'リアルタイム音声認識',
                    httpUrl: '',
                    wsUrl: 'wss://api.voice.dolphin-ai.jp/v1/asr/ws'
                },
                {
                    name: '録音ファイル書き起こし',
                    httpUrl: 'https://api.voice.dolphin-ai.jp/v1/asrfile/upload/vip',
                    httpUrl2: 'https://api.voice.dolphin-ai.jp/v1/asrfile/result',
                    httpUrl3: 'https://api.voice.dolphin-ai.jp/v1/asrfile/tasks',
                    wsUrl: ''
                },
                // {
                //     name: 'オフライン音声認識',
                //     httpUrl: 'チュートリアル',
                //     wsUrl: ''
                // }
            ],
            copyText: 'クリックして連携情報をコピー',
            dialog: {
                confirmBtn: '確定',
                cancelBtn: 'キャンセル'
            },
            form: {
                name: 'プロジェクト名：',
                desc: 'プロジェクト説明：',
                formTitle: '新規プロジェクト'
            }
        },
        term: {
            h2: '単語登録',
            info1: '* インポートファイルフォーマットはtxt、3MB未満、UTF-8エンコーディングでなければなりません。<br/>* 具体的なフォーマットについては、',
            info3: '* インポートファイルフォーマットはtxt、30MB未満、UTF-8エンコーディングでなければなりません。<br/>* 具体的なフォーマットについては、',
            info2: 'を参照してください。',
            tabTitles: ['単語', 'NG単語', '強制置換文字', '言い淀み'],
            tip1: '合計',
            tip2: 'グループ、さらに',
            tip3: 'グループ追加可能',
            tip4: 'ヒント：100文字まで入力できます',
            placeholder1: '単語辞書名を入力してください',
            placeholder2: 'NG単語辞書名を入力してください',
            placeholder3: '強制置換辞書名を入力してください',
            placeholder4: '内容を入力してください',
            btn1: '単語辞書のインポート',
            btn2: '詳細',
            btn3: '更新',
            btn4: 'ダウンロード',
            btn5: '削除',
            btn6: 'NG単語辞書のインポート',
            btn7: '強制置換辞書のインポート',
            btn8: 'デフォルトに戻す',
            btn9: '変更を保存する',
            tableTitles: [
                '単語辞書名',
                'ID',
                '単語数',
                '更新時間',
                '操作',
                'NG単語辞書名',
                '強制置換辞書名',
            ],
            totla: '共',
            page_total2: '条记录',
            page_total3: '',
            addDialog: {
                title: '単語辞書のインポート',
                label1: 'インポートタイプ：',
                label2: 'インポートファイル：',
                radioList: ['単語', '文書エンハンス'],
                uploadTip: 'ローカルファイルを選択してアップロードする',
                tip: '現在、人名、地名、組織名の抽出のみをサポートしています。'
            },
            fileTip1: 'ファイルをアップロードしてください',
            fileTip2: 'ファイルサイズが制限を超えています',
            fileTip3: 'ファイルフォーマットはtxtでなければなりません',
            delTip1: '単語辞書が削除されました',
            delTip2: 'NG単語辞書が削除されました',
            delTip3: '強制置換辞書が削除されました',
            delTip4: '削除がキャンセルされました',
            updateTip: '更新成功',
            uploadSuccess: 'アップロード成功',
            updateDialog: {
                title: '単語辞書の更新'
            },
            delInfo: {
                title: 'ヒント',
                text1: 'この単語辞書を削除しますか？',
                text2: 'NG単語辞書を削除しますか？',
                text3: '強制置換辞書を削除しますか？',
                confirmBtn: '確定',
                cancelBtn: 'キャンセル'
            },
            template1: '単語辞書テンプレート.txt',
            template2: 'NG単語辞書テンプレート.txt',
            template3: '強制置換辞書テンプレート.txt',
            detailTitle: ['元の文字', '置換文字']
        },
        cost: {
            h2: '费用明细',
            info: '您可以查看每个引擎的实际使用费。',
            tabNames: ['Cloud API機能', '离线能力'],
            downloadText: '发票',
            titles: ['请求合计', '账单明细'],
            overview: {
                title1: '发票金额(含税)',
                title2: '小计',
                title3: '消费税',
                unit: 'JPY'
            },
            tableTitles: ['引擎列表', '使用量', '使用费用'],
            downloadInfo: {
                title: '下载贷方发票',
                text: '下载 PDF 格式的信用发票。信用发票上的帐单名称将是您注册的帐单名称。如果您不介意，请选择“下载”。',
                downloadBtn: '下载',
                cancelBtn: '取消'
            }
        },
        user: {
            h2: 'ご登録内容',
            info: '*請求書宛先のデフォルト値は会社名です。',
            listTitles:[
                'メールアドレス',
                'パスワード',
                '苗字（申込者）',
                '名前（申込者）',
                '会社名',
                '部署名',
                '役職',
                '電話番号',
                '郵便番号',
                '住所',
                '請求書宛先',
                '支払い方法',
                '信用卡'
            ],
            pay: [
                'クレジットカード払い',
                '請求書払い'
            ],
            payType: [
                'Cloud API機能',
                'On-Device機能'
            ],
            payTip: '* 我们接受VISA、MasterCard、Diners Club、JCB和美国运通卡',
            btn: '変更',
            pwdForm: {
                pwd: '現パスワード：',
                newPwd: '新しいパスワード：',
                confirm: '新しいパスワード(確認)：',
                formTitle: 'パスワード変更',
                tip: '*パスワード更新後、ログイン画面へ遷移します。'
            },
            infos: {
                formTitle: '登録内容変更',
                surname: '苗字（申込者）：',
                name: '名前（申込者）：',
                company: '会社名：',
                department: '部署名：',
                jobTitle: '役職：',
                tel: '電話番号：',
                zipCode: '郵便番号：',
                addr: '住所：',
                invoiceRecipient: '請求書宛先：',
                tip: '*法人の場合は法人格と社名を略さないでご入力ください。<br/>（例）株式会社DolphinVoice'
            }
        }
    }
}